<template>
	<div class="details">
		<div class="contains">
			<div class="con_top">
                <div class="con_top_left">
				    <img style="width:360px;height:360px" src="../../assets/images/audit/800 800.jpg">
                </div>
				<div class="con_top_right">
					<h2>代码审计</h2>
					<p class="new_tit">为客户提供应用系统代码审计服务，确保能从源码层次上控制应用系统的安全风险</p>
                    <div class="pri_box">
					    <p class="title">产品价格<i>￥</i><span class="price">在线咨询</span></p>
                    </div>
                    <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                            <p>基于《信息安全技术—代码安全审计规范》 、《联网软件源代码安全审计规范》、《电信网和互联网管理安全等级保护要求》、《信息系统安全等级保护基本要求》等要求</p>
                        </div>
					</div>
				
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>
			</div>
		</div>
		<div class="conten_body">  
			<div class="conten_left">
                <div class="summary">
                    <div class="summary_title">
                        <h3>产品概述</h3>
                    </div>
                    <div class="summary_content">
                        <div class="left">
                            <p>基于《信息安全技术—代码安全审计规范》 、《联网软件源代码安全审计规范》、《电信网和互联网管理安全等级保护要求》、《信息系统安全等级保护基本要求》等要求，为客户提供应用系统代码审计服务，确保能从源码层次上控制应用系统的安全风险。</p>
                        </div>
                        <div class="right">
                            <img src="../../assets/images/audit/01.jpg" alt="">
                        </div>
                    </div>
                </div>

                <div class="scenarios">
                    <div class="scenarios_title">
                        <h3>应用场景</h3>
                    </div>
                    <div class="scenarios_content">
                        <div class="left">
                            <ul>
                                <li>
                                    <h4>1.产品上线前检测</h4>
                                    <p>在产品正式上线前进行一次全方位的代码审计，是基本要求，在产品上线前进行一次代码检测，利于提前规避风险。</p>
                                </li>
                                <li>
                                    <h4>2.为研发人员赋能</h4>
                                    <p>通过代码审计报告，让研发人员更加了解代码中存在的漏洞原理，提高代码安全生产能力，防患于未然。</p>
                                </li>
                            </ul>
                        </div>
                        <div class="right">
                            <img src="../../assets/images/audit/03.jpg" alt="">
                        </div>
                    </div>
                </div>

                <div class="develop">
                    <div class="develop_title">
                        <h3>业务开展的必要性</h3>
                    </div>
                    <div class="develop_content">
                        <div class="develop_one">
                            <div class="left">
                                <h4>1.代码安全问题频频发生。</h4>
                                <ul>
                                    <li>a.96%的安全事件由代码安全漏洞导致</li>
                                    <li>b.40%的软件项目存在程序后门</li>
                                    <li>c.53%的软件项目存在SQL注入</li>
                                    <li>d.10%的软件项目存在命令执行漏洞</li>
                                    <li>e.中国企业每年因安全事件损失百亿美元</li>
                                    <li>f.普通软件工程师每1000行代码存在150个缺陷</li>
                                    <li>g.漏洞修复平均时间为120天</li>
                                </ul>
                            </div>
                            <div class="right">
                                <img src="../../assets/images/audit/04.jpg" alt="">
                            </div>
                        </div>
                        <div class="develop_two">
                            <h4>2.早检查，早防范，把96%以上的安全事件扼杀在摇篮之中。</h4>
                            <p>黑客攻击方法层出不穷,而攻击的方向越来越侧重于利用软件本身的安全漏洞，例如SQL注入清洞、CSRF漏洞等,这些漏洞主要有不良的软件架构和不安全的编程产生。开展代码安全审计能够降低原代码出现的安全混洞,构建安全的代码,提高源代码的可靠性,提高应用系统自身安全防护能力。代码安全审计能够帮助开发人员提高源代码的质量,从底层保障应用系统本身的安全,从早期降低应用系统的开发成本。</p>
                        </div>
                    </div>
                </div>

                <div class="overview">
                    <div class="overview_title">
                        <h3>服务概述</h3>
                    </div>
                    <div class="overview_content">
                        <p>代码安全审计是依据CVE公共漏洞字典表、 OWASP十大Web漏洞及设备、软件厂商公布的漏洞库漏洞,结合专业源代码扫描工具对各种程序语言编写的源代码进行安仝审计。为客户提供包括安全编码规范咨询、源代码安全现状测评、定位源代码中存在的安全漏洞、分析风险，给出修改建议等一系列服务。</p>
                        <ul>
                            <li>
                                <div class="img">
                                    <img src="../../assets/images/audit/10.png" alt="">
                                </div>
                                <p>1.程序后门</p>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="../../assets/images/audit/6.png" alt="">
                                </div>
                                <p>2.在线支付漏洞</p>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="../../assets/images/audit/8.png" alt="">
                                </div>
                                <p>3.SQL漏洞</p>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="../../assets/images/audit/9.png" alt="">
                                </div>
                                <p>4.拒绝服务漏洞</p>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="../../assets/images/audit/7.png" alt="">
                                </div>
                                <p>5.XSS漏洞</p>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="../../assets/images/audit/1.png" alt="">
                                </div>
                                <p>6.代码注入</p>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="../../assets/images/audit/2.png" alt="">
                                </div>
                                <p>7.文件上传漏洞</p>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="../../assets/images/audit/3.png" alt="">
                                </div>
                                <p>8.登录认证漏洞</p>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="../../assets/images/audit/4.png" alt="">
                                </div>
                                <p>9.越权漏洞</p>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="../../assets/images/audit/5.png" alt="">
                                </div>
                                <p>10.CSRF漏洞</p>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="service">
                    <div class="service_title">
                        <h3>服务内容</h3>
                    </div>
                    <div class="service_content">
                        <div class="content_one">
                            <h4>1.安全编码规范咨询：</h4>
                            <p>为系统开发人员提供安全编码规范、规则的咨询和建议,提前避兔不安全的编码方式,提高源代码自身的安全性。</p>
                        </div>
                        <div class="content_one">
                            <h4>2.源代码安全现状测评：</h4>
                            <p>利用数据流分析引撃、语义分析引撃、控制流分析引撃等技术,采用专业的源代码安全审计工具对源代码安全问题进行分析和检测并验证,从而对源代码安全漏洞进行定级,给出安全漏洞分析报告等,帮助软件开发的管理人员统计和分析当前阶段软件安全的风险、趋势,跟踪和定位软件安全漏洞,提供软件安全质量方面的真实状态信息</p>
                        </div>
                        <div class="content_one">
                            <h4>3.源代码整改咨询：</h4>
                            <p>依据源代码安全审计结果,对源代码安全漏洞进行人工审计,并依据安全漏洞问题给出相应修改建议,协助系统开发人员对源代码进行修改。</p>
                        </div>
                        <div class="content_two">
                            <h4>4.代码安全审计服务流程：</h4>
                            <div class="img">
                                <img src="../../assets/images/audit/05.jpg" alt="">
                            </div>
                        </div>
                        <div class="content_three">
                            <h4>5.多种专业代码审计工具：</h4>
                            <div class="img">
                                  <img src="../../assets/images/audit/06.jpg" alt="">
                                  <img src="../../assets/images/audit/07.jpg" alt="">
                                  <img src="../../assets/images/audit/08.jpg" alt="">
                                  <img src="../../assets/images/audit/09.jpg" alt="">
                                  <img src="../../assets/images/audit/10.jpg" alt="">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="benefits">
                    <div class="benefits_title">
                        <h3>客户收益</h3>
                    </div>
                    <div class="benefits_content">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="../../assets/images/audit/11.png" alt="">
                                </div>
                                <div class="right">
                                    <h4>明确安全隐患点</h4>
                                    <p>代码审计能够对整个信息系统的所有源代码进行检查，从整套源代码切入最终明至某个威胁点并加以验证，以此明确整体系统中的安全隐患点。</p>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="../../assets/images/audit/12.png" alt="">
                                </div>
                                <div class="right">
                                    <h4>提高安全意识</h4>
                                    <p>任何的隐患在代码审计服务中都可能造成“千里之堤溃于蚁穴”的效果，因此代码审计服务可有效督促管理人员杜绝任何一处小的缺陷，从而降低整体风险。</p>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="../../assets/images/audit/13.png" alt="">
                                </div>
                                <div class="right">
                                    <h4>提高开发人员安全技能</h4>
                                    <p>在代码审计服务人员与用户开发人员的交互过程中，可提升开发人员的技能。另外，通过专业的代码审计报告，能为用户开发人员提供安全问题的解决方案，完善代码安全开发规范。</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="advantage">
                    <div class="advantage_title">
                        <h3>我们的优势</h3>
                    </div>
                    <div class="advantage_content">
                        <p>1.优秀的安全技术团队:<span>安全博士领衔多位获得国际和国内安全资质的安全专家为您服务。</span></p>
                        <p>2.公司资质&知识产权:<span>具备国家网络信息安全主管部门颁发的权威安全认证资质及国际权威认证资质。</span></p>
                        <div class="zs">
                            <div class="left">
                                <ul>
                                    <li>国家信息安全服务资质(安全工程类)</li>
                                    <li>国家信息安全风险评估资质</li>
                                    <li>国家通信网络安全服务风险评估资质</li>
                                    <li>国家信息安全应急处理服务资质</li>
                                    <li>国家信息系统安全集成服务资质</li>
                                    <li>国家信息安全漏洞库支持单位</li>
                                    <li>国际信息安全管理体系ISO 27001认证</li>
                                    <li>国际质量管理体系ISO9001认证</li>
                                    <li>国际环境管理体系ISO 14001认证</li>
                                    <li>高新技术企业</li>
                                    <li>软件企业</li>
                                    <li>企业研究开发机构证书</li>
                                </ul>
                            </div>
                            <div class="right">
                                <img src="../../assets/images/audit/zz.jpg" alt="">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="case">
                    <div class="case_title">
                        <h3>相关案例</h3>
                    </div>
                    <div class="case_content">
                        <ul>
                            <li>1.梅州移动全业务支撑平台</li>
                            <li>2.佛山移动运营数据安全管理平台（DSP）</li>
                            <li>3.中国移动南方基地BSS生产辅助系统</li>
                        </ul>
                    </div>
                </div>

            </div>
			<!-- right -->
			<div class="conten_right">
                <div class="phone">
                    <div class="phone_left">
                        <p>服务热线(微信同号)：</p>
                        <p>188 188 60088</p> 
                        <p>188 188 63388</p>
                    </div>
                    <div class="phone_right">
                        <img src="@/assets/images/app/code.png" alt="">
                    </div>
                </div>
                <div class="hot">
                    <div class="hot_title">
                        <h4>热门产品</h4>
                    </div>
                        <div class="hot_lists">
                            <ul>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/guangdong">
                                            <img src="@/assets/images/record/guangdong.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>广东省增值电信业务...</p>
                                        <p>1、服务团队从业经...</p>
                                        <p>￥8000-40000</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/assessment">
                                            <img src="@/assets/images/app/008.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>安全评估报告（全国互联网安全管理服务平台要求提交的报告）</p>
                                        <p>App、安全评估报告...</p>
                                        <p>￥9800</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
			
		</div>
	</div>
</template>

<script type="text/javascript">
export default{

data(){
	return{
		transmitData:{
			title:"云上业务安全无忧保障计划初级套餐",
			content:"",
			price:'0',
			url:require('@/assets/images/yunsafe/222.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
    padding-left: 15px;
}

.summary{
    margin-top: 80px;
    .summary_title{
        text-align: center;
        h3{
            font-size: 29px;
            font-weight: bold;
            color: #244CA3;
        }
    }
    .summary_content{
        margin-top: 77px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .left{
            p{
                font-size: 20px;
                line-height: 30px;
                color: #244CA3;
                text-indent: 2em;
            }
        }
        .right{
            
            img{
             width: 392px;
            height: 213px;
            }
        }
    }
}

.scenarios{
    margin-top: 90px;
    .scenarios_title{
         text-align: center;
        h3{
            font-size: 29px;
            font-weight: bold;
            color: #244CA3;
        }
    }
    .scenarios_content{
        margin-top: 53px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        .left{
            width: 441px;
            height: 273px;
            ul{
                li{
                    margin-bottom: 35px;
                    h4{
                        font-size: 20px;
                        font-weight: bold;
                        color: #244CA3;
                    }
                    p{
                        font-size: 20px;
                        line-height: 26px;
                    }
                }
            }
        }
        .right{
            width: 274px;
            height: 294px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}

.develop{
    margin-top: 70px;
    .develop_title{
         text-align: center;
        h3{
            font-size: 29px;
            font-weight: bold;
            color: #244CA3;
        }
    }
    .develop_content{
        margin-top: 60px;
        .develop_one{
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
            .left{
                width: 382px;
                height: 273px;
                h4{
                    font-size: 20px;
                    line-height: 32px;
                    font-weight: bold;
                    color: #244CA3;
                }
                ul{
                    li{
                        font-size: 18px;
                        line-height: 30px;
                    }
                }
            }
            .right{
                width:  343px;
                height: 282px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .develop_two{
            margin-top: 50px;
                padding-left:20px;
            h4{
                font-size: 20px;
                    line-height: 32px;
                    font-weight: bold;
                    color: #244CA3;
            }
            p{
                font-size: 18px;
                line-height: 30px;
                text-indent: 2em;
            }
        }
    }
}

.overview{
    margin-top: 70px;
    .overview_title{
          text-align: center;
        h3{
            font-size: 29px;
            font-weight: bold;
            color: #244CA3;
        }
    }
    .overview_content{
        margin-top: 42px;
        p{
            font-size: 18px;
            line-height: 30px;
            text-indent: 2em;
        }
        ul{
            margin-top: 22px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            li{
                width: 170px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 38px;
                .img{
                    width: 85px;
                    height: 85px;
                    border: 1px solid #0E47B8;
                    border-radius: 50%;
                    img{
                        width: 100%;
                        height: 100%;
                        padding: 2px 2px;
                    }
                }
                p{
                    display: block;
                    width: 170px;
                    font-size: 18px;
                    margin-top: 10px;
                }
            }
        }
    }
}

.service{
    margin-top: 90px;
    .service_title{
          text-align: center;
        h3{
            font-size: 29px;
            font-weight: bold;
            color: #244CA3;
        }
    }
    .service_content{
        margin-top: 37px;
        padding-left: 20px;
        .content_one{
            h4{
                font-size: 18px;
                line-height: 26px;
                font-weight: bold;
                color: #0E47B8;
            }
            p{
                font-size: 18px;
                line-height: 30px;
            }
        }
        .content_two{
            h4{
                font-size: 18px;
                line-height: 26px;
                font-weight: bold;
                color: #0E47B8;
            }
            .img{
                width: 637px;
                height: 361px;
               margin: 28px auto;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .content_three{
            h4{
                font-size: 18px;
                line-height: 26px;
                font-weight: bold;
                color: #0E47B8;
            }
            .img{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 30px;
                img{
                    width: 133px;
                    height: 48px;
                }
            }
        }
    }
}

.benefits{
    margin-top: 85px;
    .benefits_title{
          text-align: center;
        h3{
            font-size: 29px;
            font-weight: bold;
            color: #244CA3;
        }
    }
    .benefits_content{
        margin-top: 43px;
        ul{
            li{
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                margin-bottom: 30px;
                .left{
                    width: 115px;
                    height: 115px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .right{
                    width: 475px;
                    h4{
                        font-size: 18px;
                        line-height: 30px;
                        font-weight: bold;
                    }
                    p{
                        font-size: 18px;
                        line-height: 30px;
                        text-indent: 2em;
                    }
                }
            }
        }
    }
}

.advantage{
    margin-top: 85px;
    .advantage_title{
          text-align: center;
        h3{
            font-size: 29px;
            font-weight: bold;
            color: #244CA3;
        }
    }
    .advantage_content{
        margin-top: 60px;
        padding-left: 50px;
        p{
            font-size: 18px;
            color: #0E47B8;
            line-break: 30px;
            font-weight: bold;
            span{
                font-size: 16px;
                line-height: 26px;
                color: black;
                font-weight: normal;
            }
        }
        .zs{
            margin-top: 12px;
            width: 774px;
            height: 393px;
            border: 1px solid black;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .left{
                width: 280px;
                ul{
                    li{
                        font-size: 16px;
                        margin-bottom: 15px;
                    }
                }
            }
            .right{
                width: 365px;
                height: 365px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.case{
    margin-top: 119px;
    .case_title{
          text-align: center;
        h3{
            font-size: 29px;
            font-weight: bold;
            color: #244CA3;
        }
    }
    .case_content{
        
        padding: 37px 50px;
        ul{
            li{
                font-size: 16px;
                margin-top: 15px;
            }
        }
    }
}


.conten_right{
	width:300px;
	background:#f4f4f4;
	float:right;
  display: block;
  min-height: 100%;
	margin-left:8px;
    .phone{
        width: 100%;
        height: 100px;
        background-color: #0E47B8;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .phone_left{
            p{
                font-size: 16px;
                line-height: 18px;
                color: white;
            }
        }
        .phone_right{
            width: 75px;
            height: 75px;
            img{
                width:100%;
                height: 100%;
                }
        }
    }
    .hot{
        margin-top: 30px;
        background-color: #F2F2F2;
        height: 380px;
        .hot_title{
            width: 300px;
            height: 35px;
            background: #0E47B8;
            h4{
                margin-left: 20px;
                font-size: 18px;
                line-height: 35px;
                color: white;
            }
        }
        .hot_lists{
            ul{
                li{
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 50px;
                    .hot_left{
                        width: 147px;
                        height: 116px;
                        img{
                            width:147px;
                            height:116px;
                        }
                    }
                    .hot_right{
                        width: 140px;
                        margin-left: 20px;
                        p{
                            font-size: 14px;
                            line-height: 25px;
                             white-space: nowrap;    
                            overflow: hidden;         
                            text-overflow: ellipsis;
                        }
                        :nth-child(1){
                            padding-right: 25px;
                            font-weight: bold;
                           
                        }
                        :nth-child(2){
                             padding-right: 25px;
                            font-size: 13px;
                             
                        }
                         :nth-child(3){
                            font-size: 16px;
                            color: #B20909;
                           
                        }
                    }
                }
            }
        }
    }

}

.contains{
    margin-top: 20px;
	padding:20px;
	background: #fff;
}
.con_top{
    display: flex;
	overflow:hidden;
}
.con_top_left{
	width:358px;
    margin-right: 75px;
}

.con_top_right{
	width:790px;
}
.con_top_right h2{
	font-size: 25px;
	color: #000000;
    font-weight: bold;
	line-height: 50px;
}
.new_tit{
	background-color:#0E47B8;

	line-height: 24px;
	font-size:16px;
    padding:10px 0;
	color:#fff;
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:10px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #FF0000;
	line-height: 47px;
    padding-left: 35px;
    padding-right: 2px;
    font-weight: bold;
}
.pri_box  .price{
	font-size: 26px;
	font-weight: bold;
	color: #FF0000;
	position: relative;
}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 4px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
        p{
            font-size: 14px;
            line-height: 28px;
        }
    }
}
.box_ti{
   margin-top:30px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_bottom{
	margin-top:10px;
	
}
.box_bottom a{
	width: 162px;
	height: 47px;
	background: #D97602;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 47px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}
.details{
	width:1200px;
	margin:0 auto;

//**/





}


</style>







